import React, { Component } from "react";

import "./map.scss";

class Map extends Component {
  render() {
    return <div id="app-map"></div>;
  }
}

export default Map;
