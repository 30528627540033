export const SET_CARGOS = "SET_CARGOS";

export const SET_CARGO = "SET_CARGO";

export const SET_VESSELS = "SET_VESSELS";
export const SET_PORTS = "SET_PORTS";
export const SET_ROUTES = "SET_ROUTES";

export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";
